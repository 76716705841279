.title {
    color: #A05969;
    font-size: 2.5em;
    font-weight: bolder;
}

h5 {
    color: #A05969;
    font-size: 1em;
    font-weight: normal;
}

.inputBox {
    /* height: 25em; */
    margin: 1em auto;
}

.container {
    border: 2px solid #A05969;
    padding: 1em;
    background-color: #FAEBE8;
    margin: 1em auto;
}

.paper {
    background-color: #FAEBE8;
    border: 3px solid #A05969;
    margin: 1em;
}

.form {
    padding: 1em;
    display: flex;
}

.simpanButton {
    background-color: #00ff00;
    float: right;
    width: 15em;
    height: 3em;
    font-weight: bold;
    margin-right: 15px;
}

.simpanButton:hover {
    background-color: #00ff00;
}

.paperKartu {
    background-color: #FAEBE8;
    border: 3px solid #A05969;
    margin: 1em auto;
    width: 33em;
    padding: 1em;
}


.paperBukti {
    background-color: #FAEBE8;
    border: 3px solid #A05969;
    margin: 1em 1em;
}

.reasonText {
    word-break: break-word;
    word-break: break-all;
}


@media only screen and (max-width: 640px) {
    .inputBox {
        margin: 0 auto;
    }

    .paperKartu {
        width: 17.5em;
    }
}
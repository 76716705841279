@font-face {
  font-family: Azonix;
  src: url("./fonts/Azonix.otf");
}

@font-face {
  font-family: Futura;
  src: url("./fonts/Futura\ Regular.ttf");
}

@font-face {
  font-family: FuturaMd;
  src: url("./fonts/futura\ medium\ bt.ttf");
}

html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
}

.root {
  background-color: #363561;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}


.imglink {
  text-align: center;
}

.centerLogo {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align:center;
  max-height: 90px;
  width: auto;
}

.imgbtn {
  padding: 3%;
}


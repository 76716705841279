@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap");

h2,
h3 {
  font-family: Azonix;
}

h4,
p {
  font-size: 1.1em;
  font-family: FuturaMd;
}

ol {
  margin-top: 0px;
  list-style-type: lower-alpha;
}

.video {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.container {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 1%;
  margin-bottom: 50px;
  flex-grow: 1;
  align-items: flex-start;
}

.root {
  background-color: #faebe8;
  margin: 0 0;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.bar {
  flex: none;
}

.root {
  background-color: #363561;
  /* max-height: 100vh; */
  max-width: 100vw;
  overflow-x: hidden;
}

.title {
  font-family: Azonix;
  display: flex;
  justify-content: center;
  padding-top: 1em;
  padding-bottom: 1em;
  color: #fff;
}

.date {
  font-family: Azonix;
  color: #fff;
  opacity: 1 !important;
}

@media only screen and (max-width: 1024px) {
  .date {
    color: #000000;
  }
}

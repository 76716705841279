@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap");

.subTitle {
  font-family: Azonix;
}

.listContents {
  font-size: 1.1em;
  font-family: FuturaMd;
}

.content {
  font-size: 1.1em;
  font-family: FuturaMd;
}

.list {
  margin-top: 0px;
}

.container {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 1%;
  margin-bottom: 50px;
  flex-grow: 1;
  align-items: flex-start;
}

.root {
  background-color: #faebe8;
  margin: 0 0;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.bar {
  flex: none;
}

.title {
    color: #A05969;
    font-weight: bolder;
    font-size: 1.3em;
    text-decoration: none;
}

.iconBack{
    font-size: 1.3em;
}

.container {
    border: 2px solid #A05969;
    padding: 1em;
    background-color: #FAEBE8;
    margin: 1em auto;
}

.inputBox {
    /* height: 25em; */
    margin: 1em auto;
}

.paper {
    background-color: #FAEBE8;
    border: 3px solid #A05969;
    margin: 1em;
}

.form {
    padding: 1em;
    display: flex;
}

.buttonAccept {
    border: 2px solid green;
    float: right;
    font-weight: bold;
    margin: 1em auto;
}
.buttonDecline {
    border: 2px solid red;
    float: right;
    font-weight: bold;
    margin: 1em auto;
}
.image {
    margin: auto;
    width: 100%;
}
.heading {
  color: #a05969;
  font-family: Azonix;
}

.text {
  color: #a05969;
  font-family: FuturaMd;
  font-weight: bold;
  font-size: 18px;
}

.scrolldesktop {
  width: 5%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.scrollmobile {
  width: 15%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.animation{
  -webkit-animation: fadein 15s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 15s; /* Firefox < 16 */
  -ms-animation: fadein 15s; /* Internet Explorer */
  -o-animation: fadein 15s; /* Opera < 12.1 */
  animation: fadein 15s;
}

.animation:hover {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

@keyframes fadein {
    from { opacity: 0.5; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0.5; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0.5; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0.5; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0.5; }
    to   { opacity: 1; }
}
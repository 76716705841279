.root {
  background-color: #faebe8;
  /* max-height: 100vh; */
  width: 100%;
}

.title {
  font-family: Azonix;
  display: flex;
  justify-content: center;
  padding-top: 1em;
  padding-bottom: 0.5em;
  color: #000000;
}

.disclaimer {
  font-family: FuturaMd;
  margin-left: 1.1em;
  font-weight: bold;
  font-size: 1.1em;
}

.paper {
  background: #a05969;
  margin: 0 1.5em;
  border-radius: 0.5em;
  margin-bottom: 2em;
}

.subJudul {
  font-family: Azonix;
  color: #faebe8;
  display: flex;
  justify-content: center;
  padding-top: 1em;
}

.list {
  color: #faebe8;
  text-align: center;
  list-style-type: none;
  padding-left: 0;
  font-family: FuturaMd;
  padding-bottom: 1em;
  font-size: 1.3em;
}

@media only screen and (max-width: 640px) {
  .disclaimer {
    margin-bottom: 1em;
    margin-left: 1.5em;
  }
  .paper {
    width: 20em;
  }
}

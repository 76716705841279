@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap);
@font-face {
  font-family: Azonix;
  src: url(/static/media/Azonix.9091792c.otf);
}

@font-face {
  font-family: Futura;
  src: url("/static/media/Futura Regular.35d22d0d.ttf");
}

@font-face {
  font-family: FuturaMd;
  src: url("/static/media/futura medium bt.4e762c29.ttf");
}

html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
}

.About_root__2x45O {
  background-color: #f6ac93;
  /* max-height: 100vh; */
  max-width: 100vw;
}

.About_title__1vhXy {
  font-family: Azonix;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 1em;
}

.About_logo__JeK-w {
  max-width: 3em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.About_paper__1LSLu {
  background-color: #faebe8;
  margin: auto 4em;
  border-radius: 2em;
  margin-bottom: 3em;
}

.About_content__ga2RO {
  padding: 2em;
  font-family: FuturaMd;
  font-size: 1.3em;
  /* font-weight: bold; */
}

@media only screen and (max-width: 640px) {
  .About_paper__1LSLu {
    margin: auto 1em;
    margin-bottom: 2em;
    margin-top: 1em;
  }
}

.Timeline_root__1lcI8 {
  background-color: #363561;
  /* max-height: 100vh; */
  max-width: 100vw;
  overflow-x: hidden;
}

.Timeline_title__2i-eF {
  font-family: Azonix;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 1em;
  padding-bottom: 1em;
  color: #fff;
}

.Timeline_date__1QnxD {
  font-family: Azonix;
  color: #fff;
  opacity: 1 !important;
}

@media only screen and (max-width: 1024px) {
  .Timeline_date__1QnxD {
    color: #000000;
  }
}

.navbar_logoTitle__cVSN5 {
  font-family: Azonix;
}

.Home_heading__3ICvP {
  color: #a05969;
  font-family: Azonix;
}

.Home_text__2l_YL {
  color: #a05969;
  font-family: FuturaMd;
  font-weight: bold;
  font-size: 18px;
}

.Home_scrolldesktop__2vVZV {
  width: 5%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.Home_scrollmobile__-brER {
  width: 15%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.Home_animation__2KMje{
  -webkit-animation: Home_fadein__1TP5l 15s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  animation: Home_fadein__1TP5l 15s;
}

.Home_animation__2KMje:hover {
  -webkit-animation: Home_fadein__1TP5l 2s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  animation: Home_fadein__1TP5l 2s;
}

@keyframes Home_fadein__1TP5l {
    from { opacity: 0.5; }
    to   { opacity: 1; }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes Home_fadein__1TP5l {
    from { opacity: 0.5; }
    to   { opacity: 1; }
}

/* Internet Explorer */

/* Opera < 12.1 */
.Lomba_root__26IOa {
  background-color: #faebe8;
  /* max-height: 100vh; */
  width: 100%;
}

.Lomba_title__2fDjh {
  font-family: Azonix;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 1em;
  padding-bottom: 0.5em;
  color: #000000;
}

.Lomba_disclaimer__2hVQb {
  font-family: FuturaMd;
  margin-left: 1.1em;
  font-weight: bold;
  font-size: 1.1em;
}

.Lomba_paper__1Lr0Z {
  background: #a05969;
  margin: 0 1.5em;
  border-radius: 0.5em;
  margin-bottom: 2em;
}

.Lomba_subJudul__2pnbZ {
  font-family: Azonix;
  color: #faebe8;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 1em;
}

.Lomba_list__4DyMo {
  color: #faebe8;
  text-align: center;
  list-style-type: none;
  padding-left: 0;
  font-family: FuturaMd;
  padding-bottom: 1em;
  font-size: 1.3em;
}

@media only screen and (max-width: 640px) {
  .Lomba_disclaimer__2hVQb {
    margin-bottom: 1em;
    margin-left: 1.5em;
  }
  .Lomba_paper__1Lr0Z {
    width: 20em;
  }
}

h2,
h3 {
  font-family: Azonix;
}

h4,
p {
  font-size: 1.1em;
  font-family: FuturaMd;
}

ol {
  margin-top: 0px;
  list-style-type: lower-alpha;
}

.petunjuk_video__GYF8R {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.petunjuk_video__GYF8R iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.petunjuk_container__3GY2_ {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 1%;
  margin-bottom: 50px;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.petunjuk_root__3wFZz {
  background-color: #faebe8;
  margin: 0 0;
  height: 100%;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.petunjuk_bar__2x2bO {
  -webkit-flex: none;
          flex: none;
}

.faq_subTitle__3S-2T {
  font-family: Azonix;
}

.faq_listContents__uiYpQ {
  font-size: 1.1em;
  font-family: FuturaMd;
}

.faq_content__TFY77 {
  font-size: 1.1em;
  font-family: FuturaMd;
}

.faq_list__Hp2iZ {
  margin-top: 0px;
}

.faq_container__vwmxG {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 1%;
  margin-bottom: 50px;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.faq_root__7XIX6 {
  background-color: #faebe8;
  margin: 0 0;
  height: 100%;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.faq_bar__1QdHx {
  -webkit-flex: none;
          flex: none;
}

h2,
h3 {
  font-family: Azonix;
}

h4,
p {
  font-size: 1.1em;
  font-family: FuturaMd;
}

ol {
  margin-top: 0px;
  list-style-type: lower-alpha;
}

.webinar_video__1fk-x {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.webinar_video__1fk-x iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.webinar_container__kLLvd {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 1%;
  margin-bottom: 50px;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.webinar_root__YltSR {
  background-color: #faebe8;
  margin: 0 0;
  height: 100%;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.webinar_bar__cLsbb {
  -webkit-flex: none;
          flex: none;
}

.Layout_root__2HGc_ {
  background-color: #363561;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}


.Layout_imglink__17_Uy {
  text-align: center;
}

.Layout_centerLogo__7iYMJ {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align:center;
  max-height: 90px;
  width: auto;
}

.Layout_imgbtn__i-cTv {
  padding: 3%;
}


.DataTim_title__cZspz {
    color: #A05969;
    font-size: 2.5em;
    font-weight: bolder;
}

h5 {
    color: #A05969;
    font-size: 1em;
    font-weight: normal;
}

.DataTim_inputBox__yoy2u {
    /* height: 25em; */
    margin: 1em auto;
}

.DataTim_container__3g5jm {
    border: 2px solid #A05969;
    padding: 1em;
    background-color: #FAEBE8;
    margin: 1em auto;
}

.DataTim_paper__MPrxq {
    background-color: #FAEBE8;
    border: 3px solid #A05969;
    margin: 1em;
}

.DataTim_form__VcJ6V {
    padding: 1em;
    display: -webkit-flex;
    display: flex;
}

.DataTim_simpanButton__1FLpY {
    background-color: #00ff00;
    float: right;
    width: 15em;
    height: 3em;
    font-weight: bold;
    margin-right: 15px;
}

.DataTim_simpanButton__1FLpY:hover {
    background-color: #00ff00;
}

.DataTim_paperKartu__3lxq8 {
    background-color: #FAEBE8;
    border: 3px solid #A05969;
    margin: 1em auto;
    width: 33em;
    padding: 1em;
}


.DataTim_paperBukti__2wBb9 {
    background-color: #FAEBE8;
    border: 3px solid #A05969;
    margin: 1em 1em;
}

.DataTim_reasonText__2O1hY {
    word-break: break-word;
    word-break: break-all;
}


@media only screen and (max-width: 640px) {
    .DataTim_inputBox__yoy2u {
        margin: 0 auto;
    }

    .DataTim_paperKartu__3lxq8 {
        width: 17.5em;
    }
}
.DataVerif_title__3iQGF {
    color: #A05969;
    font-weight: bolder;
    font-size: 1.3em;
    text-decoration: none;
}

.DataVerif_iconBack__mdKPh{
    font-size: 1.3em;
}

.DataVerif_container__2ws9u {
    border: 2px solid #A05969;
    padding: 1em;
    background-color: #FAEBE8;
    margin: 1em auto;
}

.DataVerif_inputBox__1gf3F {
    /* height: 25em; */
    margin: 1em auto;
}

.DataVerif_paper__nf1yD {
    background-color: #FAEBE8;
    border: 3px solid #A05969;
    margin: 1em;
}

.DataVerif_form__2JJXy {
    padding: 1em;
    display: -webkit-flex;
    display: flex;
}

.DataVerif_buttonAccept__1gHhB {
    border: 2px solid green;
    float: right;
    font-weight: bold;
    margin: 1em auto;
}
.DataVerif_buttonDecline__3aD9Z {
    border: 2px solid red;
    float: right;
    font-weight: bold;
    margin: 1em auto;
}
.DataVerif_image__2llGJ {
    margin: auto;
    width: 100%;
}
.DataActive_title__2iFEX {
    color: #A05969;
    font-weight: bolder;
    font-size: 1.3em;
    text-decoration: none;
}

.DataActive_iconBack__2Tvqn{
    font-size: 1.3em;
}

.DataActive_container__2SayJ {
    border: 2px solid #A05969;
    padding: 1em;
    background-color: #FAEBE8;
    margin: 1em auto;
}

.DataActive_inputBox__3CTud {
    /* height: 25em; */
    margin: 1em auto;
}

.DataActive_paper__kplhz {
    background-color: #FAEBE8;
    border: 3px solid #A05969;
    margin: 1em;
}

.DataActive_form__3lgLv {
    padding: 1em;
    display: -webkit-flex;
    display: flex;
}

.DataActive_buttonAccept__NlaNm {
    border: 2px solid green;
    float: right;
    font-weight: bold;
    margin: 1em auto;
}
.DataActive_buttonDecline__1qi2N {
    border: 2px solid red;
    float: right;
    font-weight: bold;
    margin: 1em auto;
}
.DataActive_image__Z6OHZ {
    margin: auto;
    width: 100%;
}


.root {
  background-color: #f6ac93;
  /* max-height: 100vh; */
  max-width: 100vw;
}

.title {
  font-family: Azonix;
  display: flex;
  justify-content: center;
  padding: 1em;
}

.logo {
  max-width: 3em;
  display: flex;
  justify-content: center;
}

.paper {
  background-color: #faebe8;
  margin: auto 4em;
  border-radius: 2em;
  margin-bottom: 3em;
}

.content {
  padding: 2em;
  font-family: FuturaMd;
  font-size: 1.3em;
  /* font-weight: bold; */
}

@media only screen and (max-width: 640px) {
  .paper {
    margin: auto 1em;
    margin-bottom: 2em;
    margin-top: 1em;
  }
}
